import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Suspense, lazy } from "react";
import Loading from "./components/reusableComponenets/loading/Loading.tsx";
const LazyApp = lazy(() => import("./App.tsx"));
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <LazyApp />
    </Suspense>{" "}
  </React.StrictMode>
);
