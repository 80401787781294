import ReactLoading from "react-loading";
import MovingText from "react-moving-text";

function Loading() {
  return (
    <div className="flex bg-[#3aafa9] text-3xl text-[#17252a] font-bold  flex-col gap-12 justify-center items-center w-screen h-screen">
      <ReactLoading type={"spin"} color="#def2f1" />
      <MovingText
        type="typewriter"
        dataText={["Loading ...", "Chargement...", "bezig met laden ..."]}
      />
    </div>
  );
}

export default Loading;
